import { imageTMDB } from "backend/tmdb/image";
import { useMovieDetailsTmdb } from "backend/tmdb/useMovieDetailsTmdb";
import { PATH_APP } from "constants/path";
import ButtonAddFavorite from "features/details/ButtonAddFavorite";
import DetailsRating from "features/details/DetailsRating";
import ModalYoutubeTrailer from "features/details/ModalYoutubeTrailer";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import { MediaType } from "types";
import { TMDBMovieResult } from "types/tmdb";

const customStyles = {
  content: {
    border: "none",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: 100,
    background: "#000",
  },
};

const ModalMovieDetails = ({
  movie,
  setModalInfo,
}: {
  movie: TMDBMovieResult;
  setModalInfo: React.Dispatch<React.SetStateAction<TMDBMovieResult | null>>;
}) => {
  const { data: details, isLoading: isLoadingTmdb } = useMovieDetailsTmdb({
    params: {
      tmdb_id: movie.id.toString() || "",
    },
    config: {
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * (60 * 1000),
      enabled: Boolean(movie.id),
    },
  });
  const [isOpenTrailer, setOpenTrailer] = useState(false);
  if (isLoadingTmdb || !details) return null;
  return (
    <ReactModal
      isOpen={true}
      style={customStyles}
      onRequestClose={() => {
        setModalInfo(null);
      }}
      contentLabel={details.title}
    >
      {isMobile ? (
        <div id="slider_movie" className="slider fadein" style={{ top: 0, opacity: 1 }}>
          <div className="movie-menu">
            <button className="movie-menu__close-arrow" onClick={() => setModalInfo(null)} />
            <div className="logo-filmes2" style={{ top: "10px" }}><center><img src="https://i.ibb.co/BKVrq5D/logo.gif" alt="DONFLIX" width="134" height="86"/></center></div>
            <ButtonAddFavorite
              tmdbId={movie.id.toString()}
              poster={imageTMDB.image300(details.poster_path)}
              title={details.title || details.original_title}
              year={new Date(details.release_date).getFullYear()}
              score={details.vote_average}
              mediaType={MediaType.MOVIE}
            />
          </div>
          <div className="info-container movie-backdrop js-backdrop">
            <div
              className="info-container__poster js-poster fadein"
              style={{
                backgroundImage: `-webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%), url(${imageTMDB.image1280(
                  details.poster_path
                )})`,
              }}
            ></div>
            <div className="info-container__content movie-info">
              <DetailsRating score={details.vote_average / 2} />
              <div className="movie-info__title js-title">
                {details.title || details.original_title}
              </div>
              <div className="movie-info__details">
                <div className="movie-info__genres js-genres">{details.genres?.[0]?.name}</div>
                <div className="movie-info__year js-year">
                  {new Date(details.release_date).getFullYear()}
                </div>
                <div className="movie-info__runtime js-runtime">{details.runtime} min</div>
              </div>
              <div className="controls">
                <Link to={`${PATH_APP.watchMovie}?tmdb_id=${movie.id}`}>
                  <div className="controls__play">
                    <svg
                      className="js-play play-button"
                      style={{ fill: "#888" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path d="M6 6h12v12H6z" fill="#FFF" />
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />
                    </svg>
                  </div>
                </Link>
              </div>
              <div className="synopsis-buttons">
                <div
                  className="synopsis-buttons__trailer trailer-btn trailer"
                  onClick={() => setOpenTrailer(true)}
                >
                  TRAILER
                </div>
              </div>
              <div className="movie-synopsis js-synopsis" style={{ paddingBottom: 30 }}>
                {details.overview}
              </div>
              <div className="actors" />
            </div>
            <div className="desktop-visible desktop-controls"></div>
          </div>
        </div>
      ) : (
        <div id="slider_movie" className="slider fadein" style={{ top: 0, opacity: 1 }}>
                <div className="logo-filmes" style={{ top: "10px" }}><img src="https://i.ibb.co/BKVrq5D/logo.gif" alt="DONFLIX" width="134" height="86"/></div>
          <button className="close" onClick={() => setModalInfo(null)} />
          <div className="backdrop">
            <div className="backdrop_img fadein">
              <div
                className="img"
                style={{
                  backgroundImage: `url(https://image.tmdb.org/t/p/w1280${details.backdrop_path})`,
                }}
              />
            </div>
          </div>
          <div className="movie_cont">
            <div className="incont">
              <img
                alt={details.title || details.title_english || details.title_portuguese}
                className="poster_img fadein"
                src={`https://image.tmdb.org/t/p/w780/${details.poster_path}`}
              />
              <div className="content">
                <div className="title js-title">
                  {details.title || details.title_english || details.title_portuguese}
                </div>
                <div className="title_info_cont">
                  <div className="title_info genre js-genres">{details.genres?.[0]?.name}</div>
                  <div className="title_info">
                    <span className="icon2 date" /> {new Date(details.release_date).getFullYear()}
                  </div>
                  <div className="title_info">
                    <span className="icon2 time" />{" "}
                    <span className="runtime js-runtime">{details.runtime} min</span>
                    <DetailsRating score={details.vote_average / 2} />
                  </div>
                </div>
                <div className="synopsis fadein">{details.overview}</div>
                <div className="actors" />
              </div>
            </div>
            <div id="watch_toolbox">
              <div className="toolbox_content">
                <div className="sep">
                  <div className="watch-btn">
                    <div className="icon2 play" />
                    <Link to={`${PATH_APP.watchMovie}?tmdb_id=${movie.id}`}>
                      <div className="caption">ASSISTIR AGORA</div>
                    </Link>
                  </div>
                </div>
                <div className="sep" style={{ color: "rgba(255,255,255,0.7)" }}>
                  <div className="tools">
                    <ButtonAddFavorite
                      tmdbId={movie.id?.toString()}
                      poster={imageTMDB.image300(details.poster_path)}
                      title={details.title || details.original_title}
                      year={new Date(details.release_date).getFullYear()}
                      score={details.vote_average}
                      mediaType={MediaType.MOVIE}
                    />
                    {details.videos.results?.[0]?.key ? (
                      <div
                        className="tool trailer"
                        style={{ display: "block" }}
                        onClick={() => setOpenTrailer(true)}
                      >
                        Trailer
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalYoutubeTrailer
        isOpen={isOpenTrailer}
        setIsOpen={setOpenTrailer}
        title={details?.title || details?.original_title}
        youtubeTrailerId={details.videos?.results?.[0]?.key}
      />
    </ReactModal>
  );
};

export default ModalMovieDetails;
