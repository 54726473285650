import { isMobile } from "react-device-detect";

export const LocalStorage = {
  favoriteList: "favoriteList",
  history: "history",
};

export const movieGridSettings: { fontSize: string; numItemsInRow: string; inputValue: string } =
  JSON.parse(
    localStorage.getItem("movie-grid") ||
      `{"inputValue":0,"fontSize":"12px","numItemsInRow":${isMobile ? 3 : 11}}`
  );
