import { movieGenres } from "constants/category";
import React from "react";
import classNames from "utils/classNames";

interface Props {
  setSelectedCategory: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedYear: React.Dispatch<React.SetStateAction<number | null>>;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: number | null;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
}

const nowFullYear = new Date().getFullYear();

const SidebarMovies = ({
  setSelectedYear,
  setSelectedCategory,
  selectedCategory,
  setSortBy,
  setKeyword,
}: Props) => {
  return (
    <>
      <div id="side_menu">
        <div id="menu_teaser">
          <div></div>
          <div></div>
        </div>
        <div className="incont scrollbar-hidden">
          <div id="sidetools" className="scrollbar-hidden">
            <div id="genres_box" className="scrollbar-hidden">
              {movieGenres.map((genre) => (
                <div
                  key={genre.id}
                  onClick={() => {
                    setSelectedCategory(genre.id);
                    setKeyword("");
                  }}
                  className={classNames("genre", genre.id === selectedCategory ? "activated" : "")}
                >
                  {genre.name}
                </div>
              ))}
            </div>
            <div id="sortby_box">
              <div style={{ fontSize: 11, color: "#fff" }}>
                <b>Ano:</b>&nbsp;
                <select
                  id="select_sortby"
                  onChange={(e) => {
                    setSelectedYear(Number(e.target.value));
                    setKeyword("");
                  }}
                >
                  {Array(nowFullYear - 1939)
                    .fill(0)
                    .map((_, index: number) => {
                      const year = nowFullYear - index;
                      return (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div id="sortby_box">
              <div style={{ fontSize: 11, color: "#fff" }}>
                <b>Mostrar:</b>&nbsp;
                <select
                  id="select_sortby"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                    setKeyword("");
                  }}
                >
                  <option value="popular">Popular</option>
                  <option value="top_rated">Top</option>
                  <option value="now_playing">Recente</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay"></div>
    </>
  );
};

export default SidebarMovies;
