import React, { useEffect, useRef } from "react";
import useIntersectionObserver from "./useIntersectionObserver";

export interface CheckInViewProps extends React.HTMLProps<HTMLDivElement> {
  onInView: () => void;
  rootMargin?: string;
  root?: Element | Document | null;
}

const CheckInView = ({
  onInView,
  rootMargin = "0px 0px 100px 0px",
  children,
  root,
  className = "mt-5 scrollbar-hide",
  ...props
}: CheckInViewProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { rootMargin, root });
  const isVisible = !!entry?.isIntersecting;
  useEffect(() => {
    if (isVisible) onInView();
  }, [isVisible, onInView]);
  return (
    <div ref={ref} className={className} {...props}>
      {children}
    </div>
  );
};

export default CheckInView;
