import { APP_OPENSUBTITLES_API, APP_PROXIES } from "configs";

const APP_PROXIES_NETLIFY = ["https://dev--elegant-conkies-ea0c48.netlify.app"];

export const PATH_API = {
  BYPASS_CORS_WORKERS: (url: string) => {
    const randomNumber = Math.floor(Math.random() * APP_PROXIES.length);
    return `${APP_PROXIES[randomNumber]}/?destination=${encodeURIComponent(url)}`;
  },
  BYPASS_CORS_NETLIFY: (url: string) => {
    const randomNumber = Math.floor(Math.random() * APP_PROXIES_NETLIFY.length);
    return `${APP_PROXIES_NETLIFY[randomNumber]}/?destination=${encodeURIComponent(url)}`;
  },
  API_OPENSUBTITLES: APP_OPENSUBTITLES_API,
  BYPASS_CORS_CORIO: (url: string) => {
    return `https://corsproxy.io/?${encodeURIComponent(url)}`;
  },
};

export const PATH_APP = {
  home: "/",
  tv: "/tv",
  info: "/info",
  favorite: "/favorite",
  history: "/history",
  detailsTv: "/details/tv",
  detailsMovie: "/details/movie",
  watchMovie: "/watch/movie",
  watchTV: "/watch/tv",
};
