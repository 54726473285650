import HomePage from "pages/HomePage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./assets/styles/app.scss";
import "./assets/styles/mobile.scss";
import "./assets/styles/modal.scss";
import "./assets/styles/movies.scss";
import "./assets/styles/tvshow.scss";
// import "./assets/styles/reset.scss";
import { lazy, Suspense } from "react";
import Modal from "react-modal";
import "./assets/styles/player.scss";
const MovieDetailsPage = lazy(() => import("pages/MovieDetailsPage"));
const WatchSeriesPage = lazy(() => import("pages/WatchSeriesPage"));
const WatchMoviePage = lazy(() => import("pages/WatchMoviePage"));
const PageNotFound = lazy(() => import("pages/PageNotFound"));
const SeriesPage = lazy(() => import("pages/SeriesPage"));
const SeriesDetailsPage = lazy(() => import("pages/SeriesDetailsPage"));
const FavoritePage = lazy(() => import("pages/FavoritePage"));
const HistoryPage = lazy(() => import("pages/HistoryPage"));
const InfoPage = lazy(() => import("pages/InfoPage"));

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById("root")!);

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tv" element={<SeriesPage />} />
          <Route path="/watch/movie" element={<WatchMoviePage />} />
          <Route path="/watch/tv" element={<WatchSeriesPage />} />
          <Route path="/details/movie" element={<MovieDetailsPage />} />
          <Route path="/details/tv" element={<SeriesDetailsPage />} />
          <Route path="/favorite" element={<FavoritePage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
