import { useState } from "react";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";

interface LazyImageProps extends LazyLoadImageProps {
  src: string;
  fallbackSrc?: string;
}

const DEFAULT_IMAGE_FALLBACK = "https://popcorntimeonline.xyz/css/images/poster.png";

const LazyImage = ({ fallbackSrc = DEFAULT_IMAGE_FALLBACK, ...props }: LazyImageProps) => {
  const [imgSrc, setImgSrc] = useState(props.src);
  return (
    <LazyLoadImage
      title={props.alt || ""}
      effect="opacity"
      {...props}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
};

export default LazyImage;
