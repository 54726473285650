import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "libs/react-query";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import * as serviceWorkerRegistration from "libs/app-pwa/serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </QueryClientProvider>
);

serviceWorkerRegistration.register();
