import React from "react";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    border: "none",
    top: 0,
    left: 0,
    padding: 0,
    overflow: "hidden",
    right: 0,
    bottom: 0,
    zIndex: 100,
    background: "#000",
  },
};

interface Props {
  isOpen: boolean;
  title: string;
  youtubeTrailerId: string;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
}

const ModalYoutubeTrailer = ({ isOpen, setIsOpen, title, youtubeTrailerId }: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setIsOpen(false)}
      contentLabel={title}
    >
      <button className="close" onClick={() => setIsOpen(false)} />
      <iframe
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        width="100%"
        height="100%"
        style={{ border: "none" }}
        // @ts-ignore
        src={`https://www.youtube.com/embed/${youtubeTrailerId}`}
        id="trailer"
      />
    </ReactModal>
  );
};

export default ModalYoutubeTrailer;
