import { movieGridSettings } from "constants/localStorage";
import { isMobile } from "react-device-detect";

const ChangeMovieCardSize = () => {
  const handleChangeSize = (value: number) => {
    const bodyWidth = Number(document.querySelector("body")?.clientWidth);
    const coverScale = value / 100 + 1;
    const numItemsInRow = Math.floor(bodyWidth / ((isMobile ? 110 : 133) * coverScale));
    const movie_grid = document.getElementById("movie_grid");
    if (!movie_grid) return;
    movie_grid?.style.setProperty("--columns", numItemsInRow.toString());
    const fontSize = `${12 * coverScale}px`;
    document.querySelectorAll("#movie_grid .movie .title").forEach((item) => {
      (item as HTMLElement).style.fontSize = fontSize;
    });
    document.querySelectorAll("#movie_grid .movie .year").forEach((item) => {
      (item as HTMLElement).style.fontSize = fontSize;
    });
    document.querySelectorAll("#movie_grid .movie .tools").forEach((item) => {
      (item as HTMLElement).style.fontSize = fontSize;
    });
    document.querySelectorAll("#movie_grid .movie .stars").forEach((item) => {
      (item as HTMLElement).style.fontSize = fontSize;
    });
    localStorage.setItem(
      "movie-grid",
      JSON.stringify({
        inputValue: value,
        fontSize,
        numItemsInRow: numItemsInRow.toString(),
      })
    );
  };
  return (
    <div id="bottom_panel">
      <input
        tabIndex={-1}
        type="range"
        defaultValue={Number(movieGridSettings.inputValue)}
        min={0}
        max={125}
        id="size_setter"
        onChange={(e) => handleChangeSize(e.target.valueAsNumber)}
      />
    </div>
  );
};

export default ChangeMovieCardSize;
