import { PATH_APP } from "constants/path";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";

const Header = ({
  keyword = "",
  handleChangeKeyword,
}: {
  keyword?: string;
  handleChangeKeyword?: (value: string) => void;
}) => {
  return (
    <div id="header">
      <div id="toolbar" className="nav sections">
        <NavLink to={PATH_APP.home}>
          <div className="btn movies">
            <div className="icon2 film" />
            <span>Filmes</span>
          </div>
        </NavLink>
        <NavLink to={PATH_APP.tv}>
          <div className="btn tv">
            <div className="icon2 tv" />
            <span>Séries & Animes</span>
          </div>
        </NavLink>
      </div>
      <div id="logo"><img src="https://i.ibb.co/BKVrq5D/logo.gif" alt="DONFLIX" width="134" height="86"/></div>
      <div id="menu_panel" className="flex items-center gap-x-3">
        {handleChangeKeyword ? (
          <div id="search_cont">
            <div className="icon-custom search" />
            <div id="autocomplete" />
            <input
              type="text"
              id="search_input"
              placeholder="Procurar"
              value={keyword}
              onChange={(e) => handleChangeKeyword(e.target.value)}
            />
          </div>
        ) : null}
        <NavLink to={PATH_APP.favorite} className="favs-link">
          <div style={{ height: "24px" }} className="icon heart favs"></div>
        </NavLink>
        <NavLink
          to={PATH_APP.history}
          className="w-7 h-7 mt-[10px] lg:mt-0 lg:w-5 lg:h-5 history-link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-7 h-7 lg:w-5 lg:h-5 opacity-[0.35] cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </NavLink>
        <NavLink to={PATH_APP.info} className="info-link">
          <div style={{ height: "24px" }} className="icon info"></div>
        </NavLink>
      </div>
      {isMobile ? (
        <div className="mobile-only mobile-menu">
          <NavLink to={PATH_APP.home} className="mobile-menu__item js-button-category">
            Filmes
          </NavLink>
          <NavLink to={PATH_APP.tv} className="mobile-menu__item js-button-category">
          Séries & Animes
          </NavLink>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
