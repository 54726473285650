import { TMDB_API_KEY } from "configs";
import { ofetch } from "ofetch";

const tmdbFetch = ofetch.create({
  baseURL: "https://api.themoviedb.org/3",
  onRequest({ options }) {
    options.query = options.query || {};
    options.query.api_key = TMDB_API_KEY;
  },
});

export default tmdbFetch;
