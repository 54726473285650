export enum MediaType {
  MOVIE = "movie",
  TV = "tv",
  ANIME = "anime",
  DONGHUA = "donghua",
}

export type FavoriteCardType = {
  tmdbId: string;
  poster: string;
  title: string;
  mediaType: MediaType;
  year: number;
  score: number;
};
