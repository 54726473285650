import IconHalfStar from "components/Icons/IconHalfStar";
import IconStar from "components/Icons/IconStar";
import IconStarEmpty from "components/Icons/IconStarEmpty";

const DetailsRating = (props: { score: number }) => {
  const stars = Array(5)
    .fill(0)
    .map((_, index) => <IconStarEmpty key={index} />);
  let i;
  for (i = 0; i < props.score; i++) {
    // this will loop Math.floor(props.score) times
    stars[i] = <IconStar key={i} />;
  }
  if (props.score % 1 !== 0)
    // if value is a decimal, add a half star
    stars[i - 1] = <IconHalfStar key={i - 1} />;
  return <div className="title_info stars js-rating">{stars}</div>;
};

export default DetailsRating;
