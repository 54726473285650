import { useInfiniteQuery } from "@tanstack/react-query";
import { imageTMDB } from "backend/tmdb/image";
import tmdbFetch from "backend/tmdb/tmdbFetch";
import CheckInView from "components/CheckInView";
import LazyImage from "components/LazyImage";
import MovieGrid from "features/core/MovieGrid";
import ButtonAddFavorite from "features/details/ButtonAddFavorite";
import DetailsRating from "features/details/DetailsRating";
import ChangeMovieCardSize from "features/home/components/ChangeMovieCardSize";
import ModalMovieDetails from "features/home/components/ModalMovieDetails";
import SidebarMovies from "features/home/components/SidebarMovies";
import { useDebounce } from "hooks/useDebounce";
import Header from "layouts/Header";
import { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { MediaType } from "types";
import { TMDBMovieList, TMDBMovieResult } from "types/tmdb";
import { getUniqueListBy } from "utils/helper";

const HomePage = () => {
  const [keyword, setKeyword] = useState("");
  const debouncedKeyword = useDebounce(keyword, 500);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState("popular");
  const [modalInfo, setModalInfo] = useState<TMDBMovieResult | null>(null);
  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    data: dataMovies = null,
  } = useInfiniteQuery({
    queryKey: ["Movies", sortBy, selectedCategory, selectedYear, debouncedKeyword],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const data: TMDBMovieList = await tmdbFetch(
          debouncedKeyword
            ? `/search/movie?language=pt-BR&with_original_language=en`
            : `/${
                selectedCategory || selectedYear ? "discover/movie" : `movie/${sortBy}`
              }?region=US`,
          {
            parseResponse: JSON.parse,
            params: {
              page: pageParam,
              query: debouncedKeyword,
              primary_release_year: selectedYear,
              with_genres: selectedCategory,
            },
          }
        );
        if (debouncedKeyword) {
          data.results = data.results.sort((a, b) => b.popularity - a.popularity);
        }
        return data;
      } catch (error) {
        return null;
      }
    },
    keepPreviousData: true,
    getPreviousPageParam: (firstPage) => (firstPage ? firstPage.page + 1 : undefined),
    getNextPageParam: (lastPage) => (lastPage ? lastPage.page + 1 : undefined),
    staleTime: 10 * 60 * 1000,
    cacheTime: 15 * (60 * 1000),
  });
  const moviesList: TMDBMovieResult[] = useMemo(() => {
    // Flatten the nested arrays using `flatMap` instead of `map` and `flat`
    return getUniqueListBy(
      (dataMovies?.pages?.flatMap((page) => page?.results) as TMDBMovieResult[]) || [],
      "id"
    );
  }, [dataMovies?.pages]);
  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
    setSelectedCategory(null);
    setSortBy("popular");
    setSelectedYear(null);
  };
  if (isLoading) return null;
  return (
    <div className="television-wrapper">
      <Helmet>
        <title>DONFLIX - Assistir Filmes Online Grátis, Assistir Séries Online Grátis, Assistir Animes Online Grátis em Português</title>
        <meta
          name="description"
          content="DONFLIX - Assista filmes online grátis, assista séries online grátis, assista a animes online grátis legendado e dublado em português FULL HD."
        />
      </Helmet>
      <Header keyword={keyword} handleChangeKeyword={handleChangeKeyword} />
      <SidebarMovies
        setKeyword={setKeyword}
        setSelectedCategory={setSelectedCategory}
        setSortBy={setSortBy}
        setSelectedYear={setSelectedYear}
        selectedCategory={selectedCategory}
      />
      <MovieGrid style={{ marginTop: isMobile ? "90px" : "70px" }}>
        {moviesList.map((movie) => (
          <div
            key={movie.id}
            style={{
              transitionDelay: "0s, 0.0434783s",
              opacity: 1,
              transform: "scale(1, 1)",
            }}
            onClick={() => setModalInfo(movie)}
            className="movie p1"
          >
            <LazyImage
              src={`https://image.tmdb.org/t/p/w300${movie.poster_path}`}
              alt={movie.title}
            />
            <div className="poster_slide">
              <div className="poster_slide_cont">
                <div className="poster_slide_bg" />
                <div className="poster_slide_details">
                  <div className="title">
                    {movie.title || movie.original_title}
                    <div className="year">
                      <ButtonAddFavorite
                        mediaType={MediaType.MOVIE}
                        tmdbId={movie.id.toString()}
                        poster={imageTMDB.image300(movie.poster_path)}
                        title={movie.title || movie.original_title}
                        year={new Date(movie.release_date).getFullYear()}
                        score={movie.vote_average}
                      />{" "}
                      {new Date(movie.release_date).getFullYear()}
                    </div>
                  </div>
                  <div className="details">
                    <div className="tools" style={{ display: "none", fontSize: "24.48px" }}>
                      <span className="icon2 heart fav-btn " />{" "}
                      <ButtonAddFavorite
                        mediaType={MediaType.MOVIE}
                        tmdbId={movie.id.toString()}
                        poster={imageTMDB.image300(movie.poster_path)}
                        title={movie.title || movie.original_title}
                        year={new Date(movie.release_date).getFullYear()}
                        score={movie.vote_average}
                      />{" "}
                      &nbsp;
                      <span className="icon2 download" />
                    </div>
                    <DetailsRating score={movie.vote_average / 2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </MovieGrid>
      {modalInfo ? <ModalMovieDetails movie={modalInfo} setModalInfo={setModalInfo} /> : null}
      {hasNextPage && Boolean(dataMovies?.pages.slice(-1)?.[0]?.results.length) && (
        <CheckInView onInView={fetchNextPage} className="flex justify-center pt-2 mt-5" />
      )}
      <ChangeMovieCardSize />
    </div>
  );
};

export default HomePage;
