import { movieGridSettings } from "constants/localStorage";
import { HTMLAttributes, useEffect } from "react";
import classNames from "utils/classNames";

const MovieGrid = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  useEffect(() => {
    const movie_grid = document.getElementById("movie_grid");
    if (!movie_grid) return;
    movie_grid?.style.setProperty("--columns", movieGridSettings.numItemsInRow);
    document.querySelectorAll("#movie_grid .movie .title").forEach((item) => {
      (item as HTMLElement).style.fontSize = movieGridSettings.fontSize;
    });
    document.querySelectorAll("#movie_grid .movie .year").forEach((item) => {
      (item as HTMLElement).style.fontSize = movieGridSettings.fontSize;
    });
    document.querySelectorAll("#movie_grid .movie .tools").forEach((item) => {
      (item as HTMLElement).style.fontSize = movieGridSettings.fontSize;
    });
    document.querySelectorAll("#movie_grid .movie .stars").forEach((item) => {
      (item as HTMLElement).style.fontSize = movieGridSettings.fontSize;
    });
  });
  return (
    <div id="movie_grid" className={classNames("scrollbar-hide", className)} {...props}>
      {children}
    </div>
  );
};

export default MovieGrid;
